export const fileUtil = {
    isImage(value: File | DataTransferItem): boolean {
        return value && (value.type.split('/')[0] === 'image')
    },
    isSupportedImage(value: File | DataTransferItem): boolean {
        return value && this.isImage(value) && useAppConfig().supportedImageTypesToUpload.includes(value.type)
    },
    preloadImage(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const image = new Image()

            image.onload = () => resolve(image)
            image.onerror = reject
            image.src = src
        })
    },
    blobToFile(blob: Blob, fileName = 'untitled'): File {
        return new File(
            [ blob ],
            fileName,
            {
                type: blob.type,
                lastModified: new Date().getTime()
            }
        )
    },
    async compressImage(
        file: File,
        options: { quality: number, type: string } = { quality: 0.7, type: 'image/jpeg' }
    ): Promise<File> {
        const { document } = getClientContext()

        const imageBitmap = await createImageBitmap(file)

        const canvas = document.createElement('canvas')
        canvas.width = imageBitmap.width
        canvas.height = imageBitmap.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(imageBitmap, 0, 0)

        return await new Promise((resolve) => {
            canvas.toBlob(
                blob => resolve(this.blobToFile(blob, file.name)),
                options.type,
                options.quality
            )
        })
    },
    getFileSizeInMb(file: File): number {
        return +(file.size / 1024 / 1024).toFixed(2)
    },
    validateFileSize(file: File, maxMB = 5): boolean {
        return this.getFileSizeInMb(file) < maxMB
    },
    getBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    },
    download(url: string, fileName: string): void {
        const link = document.createElement('a')

        link.href = url
        link.download = fileName

        link.click()
    }
}
